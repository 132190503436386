.App {
  text-align: center;
}

.App-header {
  background-color: white;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(40px + 2vmin);
  color: black;
}

.App-body {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: top;
  font-size: calc(40px + 2vmin);
  color: black;
}

.row {
  display: flex;
}

.column {
  flex: 50%;
  text-align: center;
}

.App-link {
  color: grey;
  font-weight: bold;
  text-decoration: none;
  font-size: calc(20px + 2vmin);
  align-items: center;
}

.App-spotify {
  box-shadow: 10px 10px 5px grey;
  vertical-align: top;
}

.Page-header {
  color: black;
  text-align: center;
  text-decoration: none;
}

.footer {
  font-style: italic;
  text-align: center;
}